import ohmyfood from "../assets/svg/projects/ohmyfood.svg";
import learnathome from "../assets/png/projects/Learn@Home.png";
import kasa from "../assets/svg/projects/kasa.svg";

export const projectsData = [
    {
        id: 1,
        projectName: "OhMyFood",
        projectDesc:
            "Ce projet est un site de réservation de restaurant en ligne. Il a été réalisé dans le cadre de ma formation Développeur FrontEnd chez Openclassrooms.",
        tags: ["HTML", "SCSS"],
        code: "https://github.com/aplainea/OhMyFood",
        demo: "https://ohmyfood.aplainea.com",
        image: ohmyfood,
    },
    {
        id: 2,
        projectName: "Learn@Home",
        projectDesc:
            "Learn@Home est une application pour les besoin de soutien scolaire. Il a été réalisé dans le cadre de ma formation Développeur FrontEnd chez Openclassrooms.",
        tags: ["Trello", "Diagramme", "Figma"],
        code: "https://trello.com/b/nbiYf4N8/learnhome-kanban",
        demo: "https://www.figma.com/community/file/1239017256980753241/Learn%40Home",
        image: learnathome,
    },
    {
        id: 3,
        projectName: "Kasa",
        projectDesc:
            "Kasa, location d'appartements entre particuliers. Il a été réalisé dans le cadre de ma formation Développeur FrontEnd chez Openclassrooms.",
        tags: ["SCSS", "React"],
        code: "https://github.com/aplainea/Kasa",
        demo: "https://kasa.aplainea.com",
        image: kasa,
    },
];

// Do not remove any fields.
// Leave it blank instead as shown below

/* 
{
    id: 1,
    projectName: 'Car Pooling System',
    projectDesc: '',
    tags: ['Flutter', 'React'],
    code: '',
    demo: '',
    image: ''
}, 
*/
