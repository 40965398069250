export const educationData = [
    {
        id: 1,
        institution: "Openclassrooms (full remote)",
        course: "Maitrise, Développeur FrontEnd",
        startYear: "2023",
        endYear: "Actuellement",
    },
    {
        id: 2,
        institution: "Université de La Rochelle",
        course: "Licence Informatique",
        startYear: "2020",
        endYear: "2022",
    },
    {
        id: 3,
        institution: "Lycée Merleau-Ponty à Rochefort",
        course: "BTS SIO option SLAM",
        startYear: "2017",
        endYear: "2019",
    },
    {
        id: 4,
        institution: "Lycée Marcel Dassault à Rochefort",
        course: "BAC STI2D option ITEC",
        startYear: "2016",
        endYear: "2017",
    },
];
