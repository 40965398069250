import cv from "../assets/pdf/CV Antoine Plaineau.pdf";
import avatar from "../assets/png/avatar/antoinep-nobg.png";

export const headerData = {
    name: "Antoine P.",
    title: "Developpeur FrontEnd",
    desciption: "",
    image: avatar,
    resumePdf: cv,
};
