export const experienceData = [
    {
        id: 1,
        company: "Sellsy (Alternance 2 ans*)",
        jobtitle: "Développeur FrontEnd Junior",
        startYear: "2022",
        endYear: "Actuellement",
    },
    {
        id: 2,
        company: "Sellsy (Stage 7 semaines*)",
        jobtitle: "Développeur FrontEnd Junior",
        startYear: "2022",
        endYear: "2022",
    },
    {
        id: 3,
        company: "Coop Atlantique (Stage 6 semaines*)",
        jobtitle: "Développeur web",
        startYear: "Mars 2019",
        endYear: "Avril 2019",
    },
    {
        id: 4,
        company: "Coop Atlantique (Stage 6 semaines*)",
        jobtitle: "Développeur web",
        startYear: "Mai 2018",
        endYear: "Juin 2018",
    },
];
