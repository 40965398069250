export const socialsData = {
    github: "https://github.com/aplainea",
    facebook: "",
    linkedIn: "https://www.linkedin.com/in/antoine-plaineau-514a80166/",
    instagram: "",
    codepen: "",
    twitter: "https://twitter.com/AntoineP_Dev",
    reddit: "",
    blogger: "",
    medium: "",
    stackOverflow: "",
    gitlab: "",
    youtube: "",
};
